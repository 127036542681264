(function ($) {
    /**
     * Plugin de RGPD SIMPLE
     * ex :
     *
     <script type="text/javascript">
     $(document).ready(function () {

                var rgpdCustom = $.rgpdCustom({
                    urlPageCookie: '{{ path('privacy-data') }}',
                    modules: [
                        {
                            key: 'analytics',
                            name: 'Google Analytics',
                            text: 'Description rapide du module. Duplexque isdem diebus acciderat malum, quod et Theophilum insontem atrox interceperat casus, et Serenianus dignus exsecratione cunctorum, innoxius, modo non reclamante publico vigore, discessit.'
                        },
                        {
                            key: 'social',
                            name: 'Social',
                            text: 'Description rapide du module social. Duplexque isdem diebus acciderat malum, quod et Theophilum insontem atrox interceperat casus, et Serenianus dignus exsecratione cunctorum, innoxius, modo non reclamante publico vigore, discessit.'
                        }
                    ]
                });

                console.log(rgpdCustom.getConsent());
                console.log(rgpdCustom.getConsent({key: 'analytics'}));
                console.log(rgpdCustom.getConsent({key: 'social'}));
            });
     </script>

     Se récupère via Twig :
        !!! Attention cela retourne une string !!!!

     {% if app.request.cookies.get('rgpd-custom-cookie') == 'true'  %}
     {{ dump('cookie ok') }}
     {% else %}
     {{ dump('cookie pas ok') }}
     {% endif %}

     * @param options
     */
    $.rgpdCustom = function (options) {

        /**********
         * LES OPTIONS
         * @type {{eltToAppend: string, cookieName: string, urlPageCookie: string}}
         */
        var defaults = {
                cookieName:				'rgpd-custom-cookie',
                urlPageCookie:			'',
                eltToAppend:            'body',
                modules:                []
            },
            plugin = this,
            options = options || {};


        /***********************************************
         * FONCTION QUI CREE LE HTML
         */
        plugin.addElements = function () {
            var settings = $.extend({}, defaults, options);
            var bannerHtml =
                '<div class="rgpd-banner">' +
                    '<div class="simple-banner">' +
                        '<div class="title"><h2>Le respect de votre vie privée avant tout</h2></div>' +
                        '<div class="infos">' +
                            '<div class="description">' +
                                '<p>Nous utilisons des cookies sur le site afin de vous proposer une meilleure expérience. Avec votre consentement, nous les utiliserons pour analyser l’utilisation du site et améliorer l’expérience globale.</p>' +
                                '<p>Merci de cliquer sur le bouton j’accepte pour donner votre accord. Pour en savoir pus, vous pouvez consulter à tout moment la page <a href="'+settings.urlPageCookie+'" title="Politique des cookies">politique des cookies</a>.</p>' +
                            '</div>' +
                            '<div class="links">' +
                                '<a href="" class="btn-gen btn-refuse-rgpd" title="Je refuse">Je refuse</a>' +
                                '<a href="" class="btn-gen btn-accept-rgpd" title="J\'accepte">J\'accepte</a>';

            if (settings.modules && settings.modules.length > 0) {
                bannerHtml +=
                    '<div><a href="" class="btn-rgdp-choose-modules" title="Personnaliser les cookies">Personnaliser les cookies</a></div>'
                    ;
            }

            bannerHtml +=
                            '</div>' +
                        '</div>' +
                    '</div>';

            if (settings.modules && settings.modules.length > 0) {
                bannerHtml +=
                '<div class="modules">' +
                    '<div class="back"><a href="" class="btn-rgdp-back">< Retour</a></div>'
                ;

                for (var i=0; i<settings.modules.length; i++) {
                    if (settings.modules[i]) {
                        bannerHtml +=
                            '<div class="module">' +
                            '<div class="description">' +
                            '<div class="name">' +
                            settings.modules[i].name +
                            '</div>' +
                            '<div class="text">' +
                            settings.modules[i].text +
                            '</div>' +
                            '</div>' +
                            '<div class="module-toggle">' +
                                '<label class="switch">' +
                                    '<input type="checkbox" checked data-key="'+settings.modules[i].key+'">' +
                                    '<span class="slider round"></span>' +
                                '</label>' +
                            '</div>' +
                            '</div>';
                    }
                }
                bannerHtml +=
                    '<div class="modules-submit"><a href="" class="btn-gen btn-rgpd-save-modules" title="Enregistrer et commencer à naviguer">Enregistrer et commencer à naviguer</a></div>'
                '</div>'
                ;
            }

            bannerHtml +=
                '</div>'
            ;

            $(settings.eltToAppend).append(bannerHtml);
        }



        /***********************************************
         * FONCTION AJOUTE LES ECOUTEURS
         */

        plugin.addListeners = function () {
            var settings = $.extend({}, defaults, options);

            /**
             * Btn enregistrer le choix des modules
             */
            $(document).on({
                click: function () {
                    plugin.storeConsent();

                    return false;
                }
            }, '.btn-rgpd-save-modules');

            /**
             * Cacher les modules
             */
            $(document).on({
                click: function () {
                    $('.simple-banner', plugin).show();
                    $('.modules', plugin).hide();
                    return false;
                }
            }, '.btn-rgdp-back');

            /**
             * Afficher les modules
             */
            $(document).on({
                click: function () {
                        $('.simple-banner', plugin).hide();
                        $('.modules', plugin).show();
                    return false;
                }
            }, '.btn-rgdp-choose-modules');

            /**
             * Ecouteur accepter les cookies
             */
            $(document).on({
                click: function () {
                    plugin.storeConsent();

                    return false;
                }
            }, '.btn-accept-rgpd');

            /**
             * Ecouteur refuser les cookies
             */
            $(document).on({
                click: function () {
                    plugin.storeConsent({allRefused: true});

                    return false;
                }
            }, '.btn-refuse-rgpd');
        }

        /*********************************************
         * Sauvegarde les choix
         */
        plugin.storeConsent = function(p) {
            var settings = $.extend({}, defaults, options);
            var allRefused = typeof p != 'undefined' && typeof p.allRefused != 'undefined' ? p.allRefused : false;

            var allAccepted = allRefused ? false : true;

            $('.module', plugin).each(function () {
                var input = $('input[type="checkbox"]', this);
                if (allRefused) {
                    plugin.setCookie(settings.cookieName + '_' + input.attr('data-key'), false, 60 * 60 * 24 * 30 * 13);
                } else {
                    if (input.prop('checked')) {
                        plugin.setCookie(settings.cookieName + '_' + input.attr('data-key'), true, 60 * 60 * 24 * 30 * 13);
                    } else {
                        allAccepted = false;
                        plugin.setCookie(settings.cookieName + '_' + input.attr('data-key'), false, 60 * 60 * 24 * 30 * 13);
                    }
                }
            });

            plugin.setCookie(settings.cookieName, allAccepted, 60*60*24*30*13);

            location.reload();
        }

        /**
         * Recupere les choix
         * @param p
         * @returns {*}
         */
        plugin.getConsent = function(p) {
            var settings = $.extend({}, defaults, options);

            var cookieName = settings.cookieName;
            if (typeof p !== 'undefined' && typeof p.key !== 'undefined' && p.key != '') {
                cookieName += '_'+p.key;
            }
            return plugin.getCookie(cookieName);
        }


        /*********************************************
         * Affiche la banniere RGPD
         */
        plugin.showBanner = function () {
            $('.rgpd-banner').show();
        }

        /**********************************************
         * Recuperation cookie
         * @param cname
         * @returns {string}
         */
        plugin.getCookie = function (cname) {
            var name = cname + "=",
                ca = document.cookie.split(';'),
                i,
                c,
                ca_length = ca.length;
            for (i = 0; i < ca_length; i += 1) {
                c = ca[i];
                while (c.charAt(0) === ' ') {
                    c = c.substring(1);
                }
                if (c.indexOf(name) !== -1) {
                    return c.substring(name.length, c.length);
                }
            }
            return null;
        }

        /**********************************************
         * Définir cookie
         * @param variable
         * @param value
         * @param expires_seconds
         */
        plugin.setCookie = function (variable, value, expires_seconds) {
            var d = new Date();
            d = new Date(d.getTime() + 1000 * expires_seconds);
            document.cookie = variable + '=' + value + '; expires=' + d.toGMTString() + ';';
        }


        /**********************************************
         * INIT DU PLUGIN (fonction interne)
         */
        plugin.init = function () {
            var settings = $.extend({}, defaults, options);
            $.data(document, 'rgpdCustom', settings);

            // ajoute les elements au dom
            plugin.addElements();

            // ajoute les ecouteurs
            plugin.addListeners();

            // check le cookie pour afficher ou non la banniere
            var cookieRgpd = plugin.getCookie(settings.cookieName);
            if (cookieRgpd === null) {
                plugin.showBanner();
            }
        }

        // lance la fonction init interne
        plugin.init();

        return plugin;
    }
}(jQuery));
